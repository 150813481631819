/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./fiveYearGraph.vue?vue&type=template&id=4ac873f5&scoped=true"
import script from "./fiveYearGraph.vue?vue&type=script&lang=js"
export * from "./fiveYearGraph.vue?vue&type=script&lang=js"
import style0 from "./fiveYearGraph.vue?vue&type=style&index=0&id=4ac873f5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac873f5",
  null
  
)

export default component.exports